html {
  width: 100%;
  height: 100%;
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif
}

@media (max-aspect-ratio: 14/12) {
  div#info {
    width: 82vw!important;
  }
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  overflow: hidden;

  background-image: url("url:./simon-full-res.jpg");

/***
  @media (max-width: 640px) { 
    background-image: url("url:./simon-full-res.jpg?width=640");
  }
  @media (max-width: 1000px) { 
    background-image: url("url:./simon-full-res.jpg?width=1000");
  }
  @media (min-width: 1000px) { 
    background-image: url("url:./simon-full-res.jpg?width=2000");
  }***/
  

  background-size: cover;
  background-position: right 0;




}


div#info {
  position: absolute;
  top: 10vh;
  right: 9vw;
  height: 93vh;
  width: 35vw;

  background-color: rgba(255, 255, 255, .6);
  @supports(backdrop-filter: blur(10px)){
    backdrop-filter: blur(10px);
    background-color: transparent;
  }


}


#info >p {
  margin: 15%;
}
